import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: 'dataUrl',
})
export class DataUrlPipe implements PipeTransform {
  private domSanitizer = inject(DomSanitizer);

  transform(doc: { type: string; content: string } | null | undefined): string | null {
    if (!doc) {
      return null;
    }
    const value = `data:${doc.type};base64,${doc.content}`;
    return this.domSanitizer.bypassSecurityTrustUrl(value) as string;
  }
}
